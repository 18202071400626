.cgv-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.cgv-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.cgv-section {
    margin-bottom: 30px;
}

.cgv-section h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #444;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.cgv-section p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

.cgv-section strong {
    color: #000;
    font-weight: bold;
}

.cgv-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.cgv-container ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .cgv-container {
        padding: 15px;
        margin: 20px auto;
    }

    .cgv-container h1 {
        font-size: 2rem;
    }

    .cgv-section h2 {
        font-size: 1.5rem;
    }

    .cgv-section p {
        font-size: 0.95rem;
    }
}
