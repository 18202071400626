.footer {
    background: linear-gradient(to right, #4facfe, #00f2fe);
    padding: 50px 0;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-heading h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-button {
    margin-bottom: 40px;
}

.footer-locate-button {
    background: transparent;
    border: 1px solid white;
    padding: 15px 30px;
    color: white;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}

.footer-locate-button:hover {
    background-color: white;
    color: #4facfe;
}

.footer-links p {
    margin: 5px 0;
}

.footer-links a {
    color: white;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-legal {
    margin-top: 20px;
    font-size: 14px;
    opacity: 0.8;
}

.footer-legal p {
    margin: 0;
}

@media (max-width: 768px) {
    .footer {
        padding: 30px;
    } 

    .footer-links p {
        margin: 15px 0;
    }
}
