.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    padding-left: 50px;
    border-bottom: 3px solid #4facfe; 
    background-color: #fff; 
}

.navbar-logo img {
    width: 150px;
    border-radius: 20px;
}

.navbar-title {
    flex-grow: 1;
    text-align: left;
    margin-left: 30px;
}

.navbar-title-link {
    text-decoration: none;
    color: inherit;
}

.navbar-title-link:hover,
.navbar-title-link:focus,
.navbar-title-link:visited {
    text-decoration: none;
}

.navbar-title-text {
    margin: 0;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        padding-left: 20px;
    }

    .navbar-title {
        margin-left: 10px;
    }

    .navbar-title-text {
        font-size: 20px;
    }
}
