.cancel-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.cancel-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
}

.cancel-col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 30px;
}

.cancel-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.cancel-card-body {
  padding: 20px;
}

.cancel-card-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.cancel-alert {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
}

.cancel-alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.cancel-alert-info {
  background-color: #d1ecf1;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

.cancel-form-label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: #495057;
}

.cancel-form-control {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cancel-form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.cancel-mb-3 {
  margin-bottom: 16px;
}

.cancel-btn {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
}

.cancel-btn-danger {
  background-color: #dc3545;
}

.cancel-btn-danger:hover {
  background-color: #c82333;
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .cancel-col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
  }
}
