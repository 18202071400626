.payment-success-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.payment-success-main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-success-hero-section {
    /* Ajoutez des styles ici si vous ajoutez du contenu dans la section hero */
    min-height: 200px;
    background-color: #f8f9fa;
}

.payment-success-message-section {
    text-align: center;
    margin: 50px 0;
}

.payment-success-message-box {
    background-color: #e6f7ff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.payment-success-message-box h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.payment-success-message-box p {
    font-size: 18px;
}

#newsletter.payment-success-container {
    background-color: #f1f1f1;
    padding: 50px 0;
    margin-top: 50px;
}

.payment-success-section-heading h4 {
    text-align: center;
    font-size: 24px;
}

.payment-success-main-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.payment-success-main-button:hover {
    background-color: #0056b3;
}

.payment-success-copyright-text {
    text-align: center;
    font-size: 14px;
    color: #6c757d;
}

.payment-success-copyright-text a {
    color: #007bff;
    text-decoration: none;
}

.payment-success-copyright-text a:hover {
    text-decoration: underline;
}
