.payment-form {
    max-width: 500px;
    margin: auto;
    margin-bottom: 20px;
    background-color: #e6f7ff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-control {
    width: 100%; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fff;
    box-sizing: border-box; 
  }
  
  .StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fff;
    box-sizing: border-box;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  
  .form-row .col {
    flex: 0 0 48%;
  }
  
  .submit-button {
    width: 100%;
    padding: 15px;
    background-color: #00aaff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0088cc;
  }
  