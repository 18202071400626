.payment-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .payment-container h2, .payment-container p {
    text-align: center;
    margin-bottom: 20px;
  }
