.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.home-row {
  display: flex;
  flex-wrap: wrap;
}

.home-col-lg-6, .home-col-lg-3, .home-col-lg-4, .home-col-lg-8, .home-col-lg-12 {
  flex: 0 0 calc(50% - 30px);
  margin: 15px;
}

.home-col-lg-8 {
  flex: 0 0 calc(66.6667% - 30px);
}

.home-col-lg-4 {
  flex: 0 0 calc(33.3333% - 30px);
}

.home-col-lg-12 {
  flex: 0 0 100%;
}

.home-offset-lg-2 {
  margin-left: calc(16.6667% + 15px);
}

.home-main-banner {
  padding: 50px 0;
}

.home-header-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.home-header-text p {
  margin-bottom: 30px;
}

.home-form {
  margin-bottom: 30px;
}

.home-form-group {
  margin-bottom: 15px;
}

.home-form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
}

.home-gradient-button {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(45deg, #4facfe, #4facfe);
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}

.home-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.home-icon-item {
  text-align: center;
}

.home-icon-item img {
  width: 50px;
  border-radius: 10px;
}

.home-section {
  margin-top: 50px;
}

.home-services {
  padding: 50px 0;
  background: #f7f7f7;
}

.home-service-item {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-service-item h4 {
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.home-service-item p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

.home-service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}

.home-gradient-button {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  color: #fff;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease;
}

.home-gradient-button:hover {
  background: linear-gradient(45deg, #00f2fe, #4facfe);
}

.home-container2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-about-us {
  padding: 60px 0;
  padding-bottom: 0;
  text-align: center;
}

.home-section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.home-section-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #666;
}

.home-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home-step-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(50% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-step-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}

.home-number-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4facfe;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 20px;
  flex-shrink: 0;
}

.home-step-content h4 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.home-step-content p {
  font-size: 1rem;
  color: #666;
}


.home-right-image img {
  max-width: 90%;
  max-height: 90%;
  margin-left: 5%;
  border-radius: 8px;
}

.home-clients {
  padding: 60px 0;
  background-color: #f7f7f7;
  text-align: center;
}

.home-section-heading h4 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.home-section-heading img {
  margin-top: 10px;
}

.home-naccs {
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.home-nacc {
  list-style: none;
  padding: 0;
  margin: 0;
}

.home-nacc li {
  display: none;
}

.home-nacc li.home-active {
  display: block;
}

.home-thumb {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.home-client-content-container {
  text-align: left;
}

.home-client-action {
  text-align: center;
  margin-top: 20px;
}

.home-gradient-button {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  color: #fff;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease;
  text-decoration: none;
}

.home-gradient-button:hover {
  background: linear-gradient(45deg, #00f2fe, #4facfe);
}

#home-pricing {
  padding: 60px 0;
  text-align: center;
}

.home-pricing-item-pro {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.home-pricing-item-pro:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}

.home-pricing-item-pro h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  height: 70px;
}

.home-pricing-item-pro p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
  height: 130px;
}

.home-icon img {
  max-width: 80px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.home-gradient-button {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  color: #fff;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease;
  text-decoration: none;
}

.home-gradient-button:hover {
  background: linear-gradient(45deg, #00f2fe, #4facfe);
}

.home-gradient-button a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .home-container {
    padding: 0 15px;
  }
  
  .home-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .home-col-lg-6, .home-col-lg-3, .home-col-lg-4, .home-col-lg-8, .home-col-lg-12 {
    flex: 0 0 100%;
    margin: 10px 0;
  }
  
  .home-col-lg-8, .home-col-lg-4, .home-col-lg-12 {
    flex: 0 0 100%;
  }
  
  .home-offset-lg-2 {
    margin-left: 0;
  }
  
  .home-header-text h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .home-header-text p {
    margin-bottom: 20px;
  }
  
  .home-form {
    margin-bottom: 20px;
  }
  
  .home-form-group {
    margin-bottom: 10px;
  }
  
  .home-form-control {
    width: 90%;
    padding: 10px;
    font-size: 1rem;
  }
  
  .home-gradient-button {
    padding: 10px;
    font-size: 1rem;
    width: 90%;
    text-align: center;
  }
  
  .home-icons {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .home-icon-item {
    margin-bottom: 0px;
  }

  .home-icon-item p {
    margin-top: 0px;
  }
  
  .home-icon-item img {
    width: 50px;
    margin-bottom: 10px;
  }
  
  .home-services {
    padding: 30px 0;
  }
  
  .home-service-item {
    padding: 20px;
  }
  
  .home-service-item h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .home-service-item p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .home-steps {
    flex-direction: column;
    align-items: center;
  }
  
  .home-step-item {
    width: 90%;
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .home-number-circle {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
    margin-right: 15px;
  }
  
  .home-step-content h4 {
    font-size: 1.2rem;
  }
  
  .home-step-content p {
    font-size: 1rem;
  }
  
  .home-right-image img {
    max-width: 100%;
    margin-left: 0;
  }

  .home-section {
    margin-top: 0;
    padding-top: 30px;
  }
  
  .home-section-title {
    font-size: 1.5rem;
  }
  
  .home-section-description {
    font-size: 1rem;
  }
  
  .home-client-content-container, .home-client-action {
    text-align: center;
  }
  
  .home-pricing-item-pro {
    padding: 20px;
  }
  
  .home-pricing-item-pro h4 {
    font-size: 1.3rem;
    height: auto;
  }
  
  .home-pricing-item-pro p {
    font-size: 1rem;
    height: auto;
  }

  #home-pricing {
    padding: 30px 0;
  }
}